// Dependencies
import React from "react"
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
import Link from "../components/Link"

const StyelguidePage = () => (
  <Layout title="Styleguide">
		<section className="container outerx2">
			<Link to="/" className="a1">Mission</Link>
		</section>
		<section className="container outerx4">
			<div className="max-l">
				<p className="p1 outerx2">
					I help perceptive brands to connect with consumers, through cultural insight and intuitive strategy
				</p>
				<p className="p1 outerx2">
					Creative, editorial and social strategy for consumer-facing corporates and luxury brands
				</p>
				<p className="p1 outerx2">
					Some strategists obsess with data and demographics. I prefer to tune into the zeitgeist. I’ve spent years engaging with luxury brands and their audiences – both as a magazine editor and a consultant – and nothing replaces creative intuition, emotive storytelling and an appreciation for the foibles of human nature.
				</p>
			</div>
		</section>
		<section className="container outerx4">
			<div className="max-m">
				<p className="p2 outerx2">
					Hi there, I’m Aleks Cvetkovic. I’m a journalist. I write about men’s fashion, style and luxury.
				</p>
				<p className="p2 outerx2">
					I’m also a content strategist. What is content strategy, anyway? It’s the process of making sure that the stories you tell resonate with your target consumer. To help with this, I understand the audience you have, assess the audience you want and shape creative content that speaks to them.
				</p>
				<p className="p2 outerx2">
					I work on creative platforms (the foundational thinking that an effective campaign is built around. It’s the core idea that informs and directs a campaign. Having a creative platform will help your marketing team to create campaigns with engaging messaging and measurable impact), integrated campaigns, role of channel (drilling down into effective content for each channel you use as a marketing tool: your website, email comms, Instagram, LinkedIn, Twitter and so on), tone of voice, and help companies to define or re-think their brand pillars and purpose.
				</p>
				<p className="p2 outerx2">
					<em>With a natural instinct for brand, Aleks helps us craft compelling, ownable and integrated content strategies to more clearly communicate who we are and what we do in a rapidly changing sector. Drawing on his journalistic background to bring authentic storytelling to everything he does, Aleks has the rare ability to contribute to big picture thinking through to rolling up his sleeves and getting stuck in. He is a trusted partner to TOG</em>
				</p>
			</div>
		</section>
		<section className="container outerx4">
			<div className="p3">
				<h3>Contact</h3>
				<p>aleks@alekscvetkovic.com</p>
			</div>
		</section>
  </Layout>
)

export default StyelguidePage

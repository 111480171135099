// Dependencies
import React from "react"
import { Link } from "gatsby"

const CustomLink = (props) => {
	const { to, href, className, style, activeClassName, children, ...linkProps } = props
	return (
		href ? (
			<a href={href} className={className} style={style} {...linkProps} target="_blank" rel="noopener noreferrer">{children}</a>
		) : to ? (
			<Link {...props} />
		) : <span className="debug">{children}</span>
	)
}

export default CustomLink // as Link
